// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar_nav__0L9h3 * {
    text-decoration: none;
    color: #adb5bd;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/styles/navbar.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,cAAc;IACd,iBAAiB;AACrB","sourcesContent":[".nav * {\r\n    text-decoration: none;\r\n    color: #adb5bd;\r\n    font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": `navbar_nav__0L9h3`
};
export default ___CSS_LOADER_EXPORT___;
